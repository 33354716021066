<template>
  <v-layout column>
    <InnerTabs
      :value.sync="activeTypeTab"
      :customHandler="onSessionTypeTabChange"
      v-if="canUsePatientCards || isSpectatorMode"
    >
      <InnerDefaultTab
        v-for="sessionType in sessionTypes"
        :key="sessionType.value"
        :value="sessionType.name"
        :active="sessionType.value === sessionTypeFromActiveTab"
      ></InnerDefaultTab>
    </InnerTabs>
    <v-form>
      <keep-alive>
        <component
          v-if="patient && accessToOtherCards && !isSpectatorMode"
          :is="'OtherMainDoctorsCard'"
          :class="['mt-6', $vuetify.breakpoint.mdAndUp ? 'mx-6' : '']"
          :isLoading.sync="loading"
          :patient="patient"
          :saveBeforeOpen="() => synchronize(true)"
          :fetchAfterClose="() => fetch(false, true)"
          @success="handleSuccess"
          @error="handleError"
        ></component>
      </keep-alive>
    </v-form>
    <v-tabs-items touchless v-model="activeTypeTab" v-if="canUsePatientCards || isSpectatorMode">
      <v-tab-item v-for="sessionType in sessionTypes" :key="sessionType.value">
        <PatientCardTypeWrapper
          :patient="patient"
          :owner="owner"
          :sessionType="sessionType.value"
          :sessions.sync="sessions"
          :activeTab.sync="activeTab"
          :isSessionTypeActive="!!activeSession"
          :loading="loading"
          @predefined="onPredefined"
          @append="onAppend"
          @clone="onClone"
          @change="onChange"
          @new="onStartNewAppointment"
          @uploaded="onUploaded"
          @copy="onCopy"
          @success="handleSuccess"
          @error="handleError"
        ></PatientCardTypeWrapper>
      </v-tab-item>
    </v-tabs-items>
    <v-layout :class="['mt-6']" column justify-center v-if="hasCard && canUsePatientCards">
      <v-layout v-if="hasCard && !owner" column align-center>
        <CardInfoLabel :value="activeTabSession"></CardInfoLabel>
        <v-layout class="mt-4" justify-center v-if="hasCard">
          <v-layout column shrink>
            <v-layout v-if="hasCard && hasP1Sync && syncP1">
              <DefaultSelect
                label="Typ (P1)"
                :value.sync="activeTabSession.card.visit_type"
                :items="visitTypes"
                :horizontal="$vuetify.breakpoint.mdAndUp"
                infoText="Wybierz typ realizowanej powyżej wizyty."
                :autosave="activeTabSession.active"
                :syncId="syncId"
                :class="[$vuetify.breakpoint.mdAndUp ? 'mr-4' : 'align-center']"
                hide-details
              >
                <InfoButton
                  slot="label-icon"
                  :class="[$vuetify.breakpoint.mdAndUp ? 'mt-1' : 'mb-1']"
                  text="Wybierz typ realizowanej powyżej wizyty."
                ></InfoButton>
              </DefaultSelect>
            </v-layout>
            <v-layout
              shrink
              align-center
              v-if="hasCard && syncP1"
              :class="[$vuetify.breakpoint.smAndDown && 'justify-center mx-4']"
            >
              <v-checkbox
                v-model="activeSession.card.sync_p1"
                :class="[
                  'zg-text-control mt-3',
                  'pt-0',
                  $vuetify.breakpoint.mdAndUp ? 'ml-4' : '',
                ]"
                :ripple="false"
                label="Wyślij zdarzenie medyczne do P1"
                hide-details
              ></v-checkbox>
              <InfoButton
                class="mt-3"
                title="Uwaga"
                text='<b>Po odznaczeniu tej opcji dane z powyższej wizyty nie zostaną wysyłane na platformę P1</b>.<br>Jeśli wykonujesz zawód medyczny, zgodnie z Rozporządzeniem MZ z dnia 26 czerwca 2020 r. w sprawie szczegółowego zakresu danych zdarzenia medycznego przetwarzanego w systemie informacji oraz sposobu i terminów przekazywania tych danych do Systemu Informacji Medycznej, od 1 lipca 2021 roku masz obowiązek przesyłania danych o świadczeniach zdrowotnych udzielanych pacjentom do platformy P1.'
              ></InfoButton>
            </v-layout>
          </v-layout>
        </v-layout>
        <v-layout align-self-center mt-3>
          <DefaultButton
            v-if="activeTabSession.card && !isSpectatorMode"
            @click.prevent="() => finishAppointmentPeselAndAddress()"
            :loading="loading"
            primary
            class="px-6"
            >Zakończ i podpisz</DefaultButton
          >
        </v-layout>
      </v-layout>
    </v-layout>
    <v-layout
      :class="['mt-6']"
      column
      justify-center
      v-if="lastAppointmentBooksyId"
    >
      <v-layout column align-center>
        <DefaultButton primary @click.prevent="goToCalendar"
          >Wróć do kalendarza</DefaultButton
        >
      </v-layout>
    </v-layout>
    <v-layout
      :class="[
        $vuetify.breakpoint.xsOnly ? 'justify-center' : 'justify-end',
        'align-center mt-6 mx-6 px-0 mb-6',
      ]"
      v-if="!isSpectatorMode"
    >
      <MoreCardActionButton
        :isLoading.sync="internalLoading"
        :patient="patient"
        :owner="owner"
        @success="handleSuccess"
        @error="handleError"
      ></MoreCardActionButton>
      <DefaultButton
        v-show="canUsePatientCards && activeTabSession.active"
        @click.prevent="() => save()"
        class="px-0 mx-3"
        primary
        >Zapisz</DefaultButton
      >
    </v-layout>
    <ConfirmDialog
      v-if="showPatientCardChangeDialog"
      :value.sync="showPatientCardChangeDialog"
      :positiveButtonText="
        showPatientCardChangeDialogRestoreMode ? 'Przywróć' : 'Zakończ'
      "
      :positiveAction="showPatientCardChangeDialogAction"
      :title="showPatientCardChangeDialogRestoreMode ? 'Przywróć' : 'Zakończ'"
      :text="
        showPatientCardChangeDialogRestoreMode
          ? 'Obecne leczenie zostanie zakończone.'
          : ''
      "
    >
      <v-layout column slot="content">
        <div class="text-subtitle-1 black--text text-center mb-1">
          {{
            showPatientCardChangeDialogRestoreMode
              ? "Czy jesteś pewien, że chcesz przywrócić poniższe leczenie?"
              : "Czy chcesz zakończyć aktualne leczenie?"
          }}
        </div>
        <template v-for="code in sessions[this.activeTab].icd10">
          <v-layout column :key="code.id" class="my-1">
            <div
              class="text-subtitle-1 black--text text-center font-weight-bold"
            >
              {{ code.code }}
            </div>
            <div
              class="text-subtitle-1 black--text text-center font-weight-bold"
            >
              {{ code.text }}
            </div>
          </v-layout>
        </template>
      </v-layout>
    </ConfirmDialog>
    <ConfirmDialog
      v-if="showNoPeselAndAddress"
      :value.sync="showNoPeselAndAddress"
      positiveButtonText="Zakończ mimo to"
      negativeButtonText="Anuluj"
      :positiveAction="finishAppointment"
      title="Uwaga!"
      text="PESEL oraz adres są wymagane przez prawo. Uzupełnij kartę pacjenta o te dane."
    ></ConfirmDialog>
    <ConfirmDialog
      v-if="showMedicalCodesErrorPopup"
      :value.sync="showMedicalCodesErrorPopup"
      positiveButtonText=""
      negativeButtonText="Zamknij"
      title="Uwaga!"
    >
      <v-flex slot="content" class="text-center">
        <DefaultLabel
          v-html="
            'Wysyłasz dane do systemu P1.<br>Wybierz przynajmniej jeden kod <b>ICD-9</b> oraz <b>ICD-10</b>.'
          "
        ></DefaultLabel>
      </v-flex>
    </ConfirmDialog>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import CRUDMixin from "@/mixins/CRUDMixin";
import PatientService from "@/services/patient.service";
import Validators from "@/mixins/Validators";
import AutosaveMixin from "@/mixins/AutosaveMixin";
import PredefinedService from "@/services/predefined.service";
import _ from "lodash";
import moment from "moment";
import { DRAWING_FIELDS } from "@/constants/patientcard/fields/types";
import BooksyService from "@/services/booksy.service";

export default {
  mixins: [CRUDMixin, Validators, AutosaveMixin],
  props: {
    isLoading: {
      default: false,
    },
    patient: {},
    owner: {},
  },
  data() {
    return {
      value: 0,
      selectedField: 0,
      patientPricing: false,
      activeTab: 0,
      activeTypeTab: undefined,
      elements: [],
      syncId: "patientCard",
      warningVisible: false,
      showNewAppointmentDialog: false,
      showAppointmentPopup: false,
      showMedicalCodesErrorPopup: false,
      isSignedAndEnd: false,
      showAppointmentHistoryFieldDialog: false,
      showSessionHistoryFieldDialog: false,
      historyFieldDialogRecord: {},
      historyFieldDialogSession: null,
      showPatientCardChangeDialog: false,
      showPatientCardChangeDialogRestoreMode: 0,
      showPatientCardChangeDialogAction: () => {},
      sessions: [],
      showNoPeselAndAddress: false,
      selectedEvent: {},
      moreButtonLoading: false,
      tabChangeEnabled: true,
      sessionFieldSets: [
        [
          {
            value: "injury",
            name: "Injury",
            label: "Przebyte urazy/operacje:",
          },
          {
            value: "disease",
            name: "Disease",
            label: "Choroby towarzyszące:",
          },
        ],
        [
          {
            value: "emotionalaspect",
            name: "EmotionalAspect",
            label: "Aspekt emocjonalny:",
          },
          {
            value: "treatment",
            name: "Treatment",
            label: "Dotychczasowe leczenie:",
          },
        ],
      ],
      visitTypes: [
        {
          value: 6,
          text: "Wizyta",
        },
        {
          value: 8,
          text: "Cykl leczenia",
        },
        {
          value: 18,
          text: "Wyrób medyczny",
        },
        {
          value: 19,
          text: "Szczepienie",
        },
      ],
    };
  },
  watch: {
    activeTab() {
      if (this.activeTab !== 0) {
        this.loadCard();
      }
    },
    activeTypeTab(newValue, oldValue) {
      this.activeTab = 0;
      if (oldValue !== undefined) {
        this.fetch();
      }
    },
    loading() {
      this.internalLoading = this.loading;
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    FileUploadForm: () => import("@/components/FileUploadForm"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    InfoButton: () => import("../components/buttons/InfoButton"),
    MoreCardActionButton: () =>
      import("@/components/buttons/MoreCardActionButton"),
    SubviewCard: () => import("@/components/cards/SubviewCard"),
    DefaultSelect: () => import("@/components/selects/DefaultSelect"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    InnerTabs: () => import("@/components/tabs/InnerTabs"),
    InnerDefaultTab: () => import("@/components/tabs/InnerDefaultTab"),
    PatientCardTypeWrapper: () =>
      import("@/components/cards/patientcard/PatientCardTypeWrapper"),
    CardInfoLabel: () => import("@/components/text/CardInfoLabel"),
    OtherMainDoctorsCard: () =>
      import("@/components/cards/patientcard/OtherMainDoctorsCard"),
  },
  computed: {
    ...mapGetters("ui", [
      "syncP1",
      "calendarDefaultDuration",
      "visibleCardTypes",
      "canUsePatientCards",
      "accessToOtherCards"
    ]),
    sessionTypeFromActiveTab() {
      if (this.activeTypeTab !== undefined) {
        return this.sessionTypes[this.activeTypeTab].value;
      } else {
        return undefined;
      }
    },
    sessionTypes() {
      return [
        {
          value: 0,
          name: "Karta fizjoterapeutyczna",
          visible: this.visibleCardTypes.indexOf(0) !== -1 || !!this.owner,
        },
        {
          value: 1,
          name: "Karta uroginekologiczna",
          visible: this.visibleCardTypes.indexOf(1) !== -1 || !!this.owner,
        },
        {
          value: 2,
          name: "Karta pediatryczna",
          visible: this.visibleCardTypes.indexOf(2) !== -1 || !!this.owner,
        },
      ].filter((sessionType) => sessionType.visible);
    },
    isSpectatorMode() {
      return !!this.owner;
    },
    internalLoading: {
      get() {
        return this.isLoading;
      },
      set(value) {
        this.$emit("update:isLoading", value);
      },
    },
    isPatientPrice: {
      get() {
        return this.activeSession.patient_price;
      },
      set(value) {
        this.$emit("update:isPatientPrice", value);
        this.activeSession.patient_price = value;
      },
    },
    chosenDate: {
      set(value) {
        this.selectedEvent = {
          type: 4,
          event: {
            date: value.date,
            time: !value.hasShedule ? this.roundHourAndMinutes() : null,
            duration: this.calendarDefaultDuration,
            override: !value.hasShedule,
          },
          patient: this.patient,
        };
        this.showAppointmentPopup = true;
      },
    },
    activeSession() {
      for (let index = 0; index < this.sessions.length; index++) {
        const element = this.sessions[index];
        if (element.active) {
          return element;
        }
      }
      return null;
    },
    activeTabSession() {
      if (this.sessions.length > 0 && this.activeTab < this.sessions.length) {
        return this.sessions[this.activeTab];
      } else {
        return null;
      }
    },
    hasPesel() {
      return this.patient && !!this.patient.pesel;
    },
    hasAddress() {
      return this.patient && !!this.patient.address;
    },
    p1RequirementsAreMet() {
      const hasP1Enabled =
        this.activeSession && this.hasCard && this.hasP1Sync && this.syncP1;

      if (!hasP1Enabled) {
        return true;
      }

      let hasIcd9 = false;
      try {
        hasIcd9 = this.activeSession.ahfs["ICD9"][0].data.codes.length > 0;
      } catch (error) {
        hasIcd9 = false;
      }

      let hasIcd10 = false;
      try {
        hasIcd10 = this.activeSession.ahfs["ICD10"][0].data.codes.length > 0;
      } catch (error) {
        hasIcd10 = false;
      }

      return hasP1Enabled && hasIcd9 && hasIcd10;
    },
    hasCard() {
      return this.activeSession && this.activeSession.card;
    },
    hasP1Sync() {
      return (
        this.activeSession &&
        this.activeSession.card &&
        this.activeSession.sync_p1
      );
    },
    lastAppointmentBooksyId() {
      if (
        this.isSignedAndEnd &&
        this.activeSession &&
        this.activeSession.last_appointment
      ) {
        return this.activeSession.last_appointment;
      }
      return false;
    },
  },
  methods: {
    setActiveTypeTab(sessionType) {
      const index = _.findIndex(this.sessionTypes, { value: sessionType });
      if (index !== -1) {
        this.activeTypeTab = index;
      } else {
        this.activeTypeTab = 0;
      }
    },
    onSessionTypeTabChange(newSessionTypeTab) {
      if (this.activeTypeTab == undefined) {
        return;
      }
      this.sessions = [];
      this.activeTypeTab = newSessionTypeTab;
    },
    roundHourAndMinutes() {
      let curr = moment();
      const minutes = 5 * Math.round(curr.minutes() / 5);
      return moment().minute(minutes).second(0).format("HH:mm");
    },
    prepareRequestSessionData(patientPrice = false) {
      if (this.activeSession) {
        let new_session = _.cloneDeep(this.activeSession);
        new_session.patient_price = patientPrice;
        for (const [key, value] of Object.entries(new_session.ahfs)) {
          if (value.length == 0) {
            continue;
          }

          if (DRAWING_FIELDS.includes(value[0].field_type)) {
            //Drawings
            delete new_session.ahfs[key];
            continue;
          }

          new_session.ahfs[key] = [new_session.ahfs[key][0]];
        }
        return new_session;
      } else {
        return null;
      }
    },
    getFirstPossibleDate() {
      this.beforeRequest();
      ScheduleService.getFirstPossibleDate()
        .then((response) => {
          this.reset();
          this.selectedEvent = {
            type: 4,
            event: {
              date: response.data.date,
              time: response.data.time,
              office: { id: response.data.office },
            },
            patient: this.patient,
          };
          this.showAppointmentPopup = true;
        })
        .catch(() => {
          this.handleError("Brak wolnych terminów");
        });
    },
    handleSuccess(message) {
      this.reset();
      this.$emit("success", message);
    },
    handleError(message) {
      this.reset();
      this.$emit("error", message);
    },
    onUploaded(value) {
      this.$emit("uploaded", value);
    },
    onClone(group) {
      this.beforeRequest();
      this.syncing();
      PatientService.cloneFields(
        this.patient.id,
        this.prepareRequestSessionData(false),
        group
      )
        .then((reply) => {
          this.sessions = reply;
          this.handleSuccess("Powielono dane");
          this.resetAutoSave();
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.handleError("Coś poszło nie tak");
        });
    },
    onChange(options) {
      this.beforeRequest();
      PatientService.changePatientCard(
        options.sessionId,
        this.prepareRequestSessionData(),
        options.mode
      )
        .then((reply) => {
          this.sessions = reply;
          this.activeTab = 0;
          this.handleSuccess("Wprowadzone zmiany zostały zapisane");
          this.resetAutoSave();
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.handleError("Coś poszło nie tak, spróbuj ponownie");
        });
    },
    fetch(message, ignoreChanges = false) {
      if (!this.patient.id == undefined) {
        return;
      }
      this.beforeRequest();
      PatientService.getPatientCards(
        this.sessionTypeFromActiveTab,
        this.patient ? this.patient.id : undefined,
        this.owner ? this.owner.id : undefined
      )
        .then((reply) => {
          if (reply.length > 0 && this.activeTypeTab == undefined) {
            this.setActiveTypeTab(reply[0].session_type);
          }

          this.sessions = reply;

          if (ignoreChanges) {
            this.resetAutoSave();
          }

          if (message) {
            this.handleSuccess(message);
          } else {
            this.reset();
          }
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.handleError("Coś poszło nie tak, spróbuj ponownie");
        });
    },
    loadCard() {
      const at = this.activeTab;
      const sessionId = this.sessions[at].id;
      if (this.activeSession && sessionId == this.activeSession.id) {
        this.$emit("active", true);
        return;
      } else {
        this.$emit("active", false);
      }
      this.beforeRequest();
      PatientService.getPatientCard(
        sessionId,
        this.patient ? this.patient.id : undefined,
        this.owner ? this.owner.id : undefined
      )
        .then((reply) => {
          this.$set(this.sessions, at, reply);
          this.reset();
        })
        .catch(() => this.handleError("Coś poszło nie tak, spróbuj ponownie"));
    },
    save() {
      this.beforeRequest();
      this.syncing();
      PatientService.editPatientCard(
        this.prepareRequestSessionData(this.savePriceToPatient)
      )
        .then((x) => {
          this.sessions = x;
          this.synced();
          this.handleSuccess("Wprowadzone zmiany zostały zapisane");
        })
        .catch(() => this.handleError("Coś poszło nie tak, spróbuj ponownie"));
    },
    onStartNewAppointment() {
      this.showNewAppointmentDialog = true;
    },
    finishAppointment() {
      if (!this.p1RequirementsAreMet) {
        this.showMedicalCodesErrorPopup = true;
        return;
      }

      this.beforeRequest();
      this.syncing();
      PatientService.finishSessionAppointment(
        this.prepareRequestSessionData(this.savePriceToPatient)
      )
        .then((x) => {
          this.sessions = x;
          this.synced();
          if (!this.hasCard && this.activeSession) {
            this.showActionButtons();
          }
          this.handleSuccess("Zakończono wizytę pomyślnie");
        })
        .catch((error) => {
          this.handleError("Coś poszło nie tak, spróbuj ponownie");
          console.log(error.response);
        });
    },
    showActionButtons() {
      this.isSignedAndEnd = true;
    },
    finishAppointmentPeselAndAddress() {
      if (this.hasPesel && this.hasAddress && !this.isSignedAndEnd) {
        this.finishAppointment();
      } else {
        this.showNoPeselAndAddress = true;
      }
    },
    async synchronize(silent) {
      if (!this.canUsePatientCards || this.owner) {
        return;
      }

      this.beforeRequest();
      this.syncing();
      const sessionData = this.prepareRequestSessionData();

      if (sessionData === null) {
        this.synced();
        this.reset();
        return;
      }
      return new Promise((resolve, reject) => {
        PatientService.editPatientCard(sessionData)
          .then(() => {
            if (this.isSyncing) {
              this.synced();
              if (!silent) {
                this.handleSuccess("Wprowadzone zmiany zostały zapisane");
              } else {
                this.reset();
              }
            } else {
              this.reset();
            }
            resolve();
          })
          .catch((error) => {
            console.log("ERROR", error, error.response);
            this.reset();
            this.handleError("Coś poszło nie tak");
            reject();
          });
      });
    },
    onAppend(data) {
      this.beforeRequest();
      PatientService.appendHistoryField(this.patient.id, data.field, data.data)
        .then(() => {
          this.handleSuccess("Wpis został uaktualniony");
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.handleError("Nie udało się uaktualnić wpisu");
        });
    },
    onPredefined(data) {
      this.beforeRequest();
      PredefinedService.applyPredefined(this.patient.id, {
        session: this.prepareRequestSessionData(),
        predefined: data.data,
      })
        .then((reply) => {
          this.sessions = reply;
          this.handleSuccess("Wprowadzone zmiany zostały zapisane");
          this.resetAutoSave();
        })
        .catch((error) => {
          console.log("ERROR", error, error.reply);
          this.handleError("Coś poszło nie tak");
        });
    },
    getDataToSelect() {
      let elements = [];

      const sessionActiveClass =
        document.getElementsByClassName("zg-session-active")[0];
      if (!sessionActiveClass) {
        return;
      }

      const textAreas =
        sessionActiveClass.getElementsByClassName("zg-html-text-area");

      // personal data form
      let patientFormInputs = document
        .getElementsByClassName("zg-patient-form")[0]
        .getElementsByClassName("v-text-field");
      Array.from(patientFormInputs).forEach((input) => {
        let textField = input.getElementsByTagName("input")[0];
        if (textField) {
          elements.push(textField);
        } else {
          elements.push(input.getElementsByTagName("textarea")[0]);
        }
      });

      // two fields in cure form
      let cureFormInputs = document
        .getElementsByClassName("zg-cure-form")[0]
        .getElementsByClassName("v-text-field");
      Array.from(cureFormInputs).forEach((input) => {
        elements.push(input.getElementsByTagName("input")[0]);
      });

      if (textAreas) {
        Array.from(textAreas).forEach((textArea, index) => {
          let autocomplete =
            textArea.getElementsByClassName("v-autocomplete")[0];
          const appointmentAddress = textArea.className.includes(
            "zg-address-appointment"
          );
          if (autocomplete) {
            let select = autocomplete
              .getElementsByClassName("v-select__slot")[0]
              .getElementsByTagName("input")[0];
            elements.push(select);
          } else if (appointmentAddress) {
            let input = textArea
              .getElementsByClassName("v-input")[0]
              .getElementsByTagName("input")[0];
            elements.push(input);
          } else {
            let qlEditor = textArea.getElementsByClassName("ql-editor")[0];
            elements.push(qlEditor);
          }
        });
      }
      return elements;
    },
    clickAndSelect(event) {
      let elements = this.getDataToSelect();
      let element = null;
      if (["input", "div"].includes(event.srcElement.localName)) {
        element = event.srcElement;
      } else if (event.srcElement.offsetParent) {
        element = event.srcElement.offsetParent.firstChild;
      } else {
        return;
      }
      const index = elements.findIndex((ele) => ele === element);
      if (index > -1 && index < elements.length) {
        this.selectedField = index;
      }
    },
    changeTabSelection(event) {
      if (!this.tabChangeEnabled) {
        console.log("Tab change disabled!");
        return;
      }
      let elements = this.getDataToSelect();
      if (elements == undefined) {
        return;
      }

      if (event.key == "Tab") {
        event.preventDefault();
        if (this.selectedField < elements.length - 1) {
          this.selectedField = this.selectedField + 1;
        } else {
          this.selectedField = 12;
        }
        const range = document.createRange();
        const sel = window.getSelection();
        const element = elements[this.selectedField];
        if (element.hasAttribute("childNodes")) {
          range.setStart(element.childNodes[element.childNodes.length - 1], 1);
          range.collapse(true);
        }
        // sel.removeAllRanges();
        // sel.addRange(range);
        element.focus();
        // range.detach();
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },
    setupTabSelection() {
      window.addEventListener("click", this.clickAndSelect);
      window.addEventListener(
        "keydown",
        _.debounce(this.changeTabSelection, 200),
        true
      );
    },
    removeTabSelection() {
      window.removeEventListener("click", this.clickAndSelect);
      window.removeEventListener("keydown", this.changeTabSelection, true);
    },
    onEditorVisible(visible) {
      if (visible) {
        this.tabChangeEnabled = false;
      } else {
        this.tabChangeEnabled = true;
      }
    },
    resetAutoSave() {
      this.$nextTick(() => {
        this.forceResetSyncing();
      });
    },
    goToCalendar() {
      BooksyService.redirectToCalendar(this.lastAppointmentBooksyId);
    },
    onCopy() {
      this.handleSuccess("Tekst został skopiowany do schowka.");
    }
  },
  beforeDestroy() {
    // this.removeTabSelection();
  },
  created() {
    if (this.canUsePatientCards || this.isSpectatorMode) {
      this.fetch();
    }
    // this.setupTabSelection();
  },
};
</script>

<style lang="scss">
.zg-header-code-text {
  color: #909090;
}
.zg-header-indicator {
  border-radius: 0px;
  height: 20px !important;
  width: 20px !important;
  margin-top: 4px !important;
  margin-left: 4px !important;
  font-size: 14px !important;
}

.zg-without-padding .v-card__text {
  padding-right: 0px;
  padding-left: 0px;
}
.zg-no-toolbar {
  height: 60px !important;
}
.zg-no-toolbar .v-toolbar__content {
  height: 0px !important;
}
.zg-with-toolbar .v-toolbar__content {
  height: 90px !important;
}
.v-tabs-items {
  background-color: inherit !important;
}
.v-window {
  overflow: visible;
}
</style>

<style scoped>
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
.full-width-style {
  width: 100%;
}
</style>
